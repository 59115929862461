import api from '../../services/api'

export default {
  state: {
    items: null
  },
  mutations: {
    SET_ITEMS_DATA (state, data) {
      state.items = data
    },
    DELETE_ITEM_ITEMS (state, id) {
      state.items.map((singleState, index) => {
        if (singleState.id === id) {
          state.items.splice(index, 1)
        } else if (singleState.post_id === id) {
          state.items.splice(index, 1)
        }
      })
    }
  },
  actions: {
    fetchData ({ commit }, type) {
      return api.get('api/' + type).then((response) => {
        commit('SET_ITEMS_DATA', response.data.data)
      })
    },
    deleteItem ({ commit }, obj) {
      return api.delete('api/' + obj.type + '/' + obj.id).then((response) => {
        commit('DELETE_ITEM_ITEMS', obj.id)
      })
    },
    restoreItem ({ commit }, obj) {
      return api.post('api/' + obj.type + '/trash/' + obj.id).then((response) => {
        commit('DELETE_ITEM_ITEMS', obj.id)
      })
    },
    permanentDelete ({ commit }, obj) {
      return api.delete('api/' + obj.type + '/trash/' + obj.id).then((response) => {
        commit('DELETE_ITEM_ITEMS', obj.id)
      })
    }
  }
}
