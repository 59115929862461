import { createStore } from 'vuex'
import user from './modules/user'
import items from './modules/items'
import media from './modules/media'

export default createStore({
  modules: {
    user,
    items,
    media
  }
})
